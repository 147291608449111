import axios from "axios";
import cleanParams from "utils/cleanParams";

const baseURL = process.env.REACT_APP_BASE_URL;
const marketplaceBaseURL = process.env.REACT_APP_MARKETPLACE_BASE_URL;

const headers = {
  PLATFORM: "WEB",
};

const customHeader = {
  PLATFORM: "WEB",
  "Api-Key": "4AfWb5AwvmW0OZ0BW6As8wS8aL9xESs5cTELQ6l0oabCu9fOVHHHsKE6sIBg2DX3",
};

export const publicProService = axios.create({
  baseURL,
  headers,
});

const proService = axios.create({
  baseURL,
  headers,
});

export default proService;

export const proService2 = axios.create({
  baseURL,
  headers,
});

export const proService3 = axios.create({
  baseURL,
  headers,
});
export const proService4 = axios.create({
  baseURL,
  headers,
});
export const marketplaceService = axios.create({
  baseURL: marketplaceBaseURL,
  headers,
});

proService.interceptors.request.use(
  async (config) => {
    try {
      config.headers = {
        authorization: `Bearer ${localStorage.suhail_user_token}`,
        ...headers,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
proService2.interceptors.request.use(
  async (config) => {
    try {
      config.headers = {
        authorization: `Bearer ${localStorage.suhail_user_token}`,
        "X-Version": 2,
        ...headers,
      };
      if (config.params) {
        config.params = cleanParams(config.params);
      }
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
proService3.interceptors.request.use(
  async (config) => {
    try {
      config.headers = {
        authorization: `Bearer ${localStorage.suhail_user_token}`,
        "X-Version": 3,
        ...headers,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

proService4.interceptors.request.use(
  async (config) => {
    try {
      config.headers = {
        authorization: `Bearer ${localStorage.suhail_user_token}`,
        "X-Version": 4,
        "api-key":
          "4AfWb5AwvmW0OZ0BW6As8wS8aL9xESs5cTELQ6l0oabCu9fOVHHHsKE6sIBg2DX3",
        ...headers,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const byToken = axios.create({
  baseURL,
  headers: customHeader,
});
