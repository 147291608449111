import React, { useState, useEffect } from "react";
import UnderMaintenanceImage from "./static/assets/img/underMaintenance.svg";
import moment from "moment";

const UnderMaintenance = ({ data }) => {
  const [showTimer, setShowTimer] = useState(false);
  const [h, setHoure] = useState("");
  const [m, setMinutes] = useState("");
  const [s, setSeconds] = useState("");

  const getTime = (dueDate) => {
    if (dueDate) {
      const closeDate = moment(dueDate, "YYYY-MM-DD HH:mm:ss");
      const duration = moment.duration(closeDate - moment(), "milliseconds");
      if (duration < 0) {
        setShowTimer(false);
        return null;
      }
      const hours = closeDate.diff(moment(), "hours");
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      setShowTimer(true);
      return { hours, minutes, seconds };
    } else {
      setShowTimer(false);
      return null;
    }
  };

  const handleTime = (value, label) => {
    return (
      <div className="under-maintenance-page-timer-card">
        <span className="under-maintenance-page-timer-time">{value}</span>
        <span className="under-maintenance-page-timer-label">{label}</span>
      </div>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const timeObject = getTime(data?.expiryDate);
      if (timeObject) {
        const { hours, minutes, seconds } = timeObject;
        setHoure(hours > 9 ? hours : `0${hours}`);
        setMinutes(minutes > 9 ? minutes : `0${minutes}`);
        setSeconds(seconds > 9 ? seconds : `0${seconds}`);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [data]);

  return (
    <div className="under-maintenance-page">
      <img src={UnderMaintenanceImage} alt="under-maintenance" />
      {showTimer && (
        <div className="under-maintenance-page-timer">
          {handleTime(h, "ساعة")}
          {handleTime(m, "دقيقة")}
          {handleTime(s, "ثانية")}
        </div>
      )}
      <span
        className="under-maintenance-main-text"
        style={{ marginTop: showTimer ? "0rem" : "3rem" }}
      >
        المنصة تحت الصيانة...
      </span>
      <span className="under-maintenance-description">{data?.message}</span>
    </div>
  );
};

export default UnderMaintenance;
