// Clean query parameters in case query parameters are not specified in the query string
const cleanParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value != null && value !== ""
    )
  );
};

export default cleanParams;
