import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentRegion } from "../../data/Atoms";
import CloseIcon from "../../static/assets/img/close.svg";
import { CITIES_MAPPING } from "../../constants";
import { useRegionsData } from "useRegionsData";
import { useMapContext } from "appContext";
import "./RegionSelect.scss";

function RegionSelect({ setShowSelectRegion, setExpanded }) {
  let history = useHistory();

  const { setSelectRegionClicked, regData } = useMapContext();
  const { CITIES1 } = useRegionsData();
  const [Region, setCurrentRegion] = useRecoilState(currentRegion);
  const handleRegionSelectCLick = (city) => {
    setCurrentRegion(CITIES1[city]);
    if (window.location.href.includes("metrics"))
      history.push(`/${city}/metrics`);
    if (window.location.href.includes("transactions")) {
      history.push(`/${city}/transactions`);
      window.location.reload();
    }
    if (window.location.href.includes("favorites"))
      history.push(`/${city}/favorites`);
    setSelectRegionClicked(true);
    setExpanded(false);
    setShowSelectRegion(false);
  };
  return (
    <div className="region-select-container">
      <div className="header-wrapper">
        <span className="header-title">اختر المنطقة</span>
        <button
          className="close-btn"
          onClick={() => {
            setSelectRegionClicked(false);
            setShowSelectRegion(false);
          }}
        >
          <img src={CloseIcon} alt="close" />
        </button>
      </div>
      <div className="actions-wrapper">
        {regData?.map((region) => (
          <div
            className={`action-button ${
              Region.key == region?.key ? "active-action-button" : ""
            }`}
            onClick={() => handleRegionSelectCLick(region?.key)}
          >
            <span>{CITIES_MAPPING[region?.key]}</span>
            {Region.key == region?.key && (
              <small style={{ color: "#C6DFFF" }}>الموقع الحالي</small>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RegionSelect;
