import React from "react";
import SaudiFlg from "../../static/assets/img/ic_Saudi_Arabia.svg";

const PhoneNumber = ({ phoneNumber, onHandleTelephoneChange }) => {
  return (
    <div className="form-group">
      <div className="input-group">
        <div className="input-group-icon number">
          <img src={SaudiFlg} alt="Saudi arabia flag" />
          +966
        </div>
        <input
          type="text"
          className="form-control text-left"
          placeholder="000 000 000"
          value={phoneNumber}
          onChange={onHandleTelephoneChange.bind(this)}
        />
      </div>
    </div>
  );
};

export default PhoneNumber;
