import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  MapContextProvider,
  UserContextProvider,
  FirebaseContextProvider,
} from "appContext";

function Providers({ children }) {
  return (
    <FirebaseContextProvider>
      <UserContextProvider>
        <MapContextProvider>
          <ThemeProvider theme={createTheme()}>{children}</ThemeProvider>
        </MapContextProvider>
      </UserContextProvider>
    </FirebaseContextProvider>
  );
}

export default Providers;
