import proService from "../helpers/axios";

export const signIn = () => proService.post(`oauth/signin`);

export const sendPhoneNumber = (phoneNumber) =>
  proService.put(`oauth/user/phone`, phoneNumber);

export const verifyPhoneNumber = (data) =>
  proService.post(`oauth/phone/verify`, data);

export const updatePhoneNumber = (data) =>
  proService.put(`oauth/user/phone/update`, data);

export const generateCustomToken = (data) =>
  proService.post(`oauth/phone/customToken`, data);

export const sendEventLog = (data) => proService.post(`/events/log`, data);

export const saveSession = () =>
  proService.post(`/accounts/userTracking/SaveSession`);

export const closeSession = (sessionId) =>
  proService.put(`/accounts/userTracking/CloseSession/${sessionId}`);
