import React, { createContext, useContext, useState, useEffect } from "react";

export const MapContext = createContext();

export const MapContextProvider = ({ children }) => {
  const [map, setMap] = useState();
  const [mode, setMode] = useState("zoning_color");
  const [mapContainer, setMapContainer] = useState();
  const [heatmapModals, setHeatmapModals] = useState();
  const [priceMarkData, setPriceMarkData] = useState(null);
  const [regData, setRegData] = useState(null);
  const [showSelectRegion, setShowSelectRegion] = useState(false);
  const [selectRegionClicked, setSelectRegionClicked] = useState(false);

  useEffect(() => {
    if (!heatmapModals) {
      fetch("https://tiles.suhail.ai/modes/")
        .then((d) => d.json())
        .then((d) => setHeatmapModals(d));
    }
  }, [heatmapModals]);

  return (
    <MapContext.Provider
      value={{
        map,
        setMap,
        mapContainer,
        setMapContainer,
        mode,
        setMode,
        heatmapModals,
        setPriceMarkData,
        priceMarkData,
        regData,
        setRegData,
        showSelectRegion,
        setShowSelectRegion,
        selectRegionClicked,
        setSelectRegionClicked,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  return useContext(MapContext);
};
