import mixpanel from "mixpanel-browser";
import { userTrackingObj } from "userTracking";
import { onUrlChange } from "./util";

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: true,
  persistence: "localStorage",
});
const routeList = [
  "metrics",
  "transactions",
  "transaction",
  "parcel",
  "profile",
  "favorites",
  "market",
  "landing-page",
  "privacy-policy",
  "agreement",
  "offer",
  "suhail-badges",
];
export const screenViewUrlMap = (key) => {
  if (key.includes("metrics")) return "Main";
  if (key.includes("transactions")) return "Transactions";
  if (key.includes("transaction")) return "Transaction";
  if (key.includes("parcel")) return "Property Detail";
  if (key.includes("profile")) return "Profile";
  if (key.includes("favorites")) return "Favorites";
  if (key.includes("market")) return "Market";
  if (key.includes("landing-page")) return "Landing-page";
  if (key.includes("privacy-policy")) return "Privacy-policy";
  if (key.includes("agreement")) return "agreement";
  if (key.includes("offer")) return "offer";
  if (key.includes("suhail-badges")) return "suhail-badges";
  if (key === "/") return "Home";

  const map = {
    "/": "Home",
    "/account/auth": "Login",
  };

  return map[key] || key;
};
  const pathname = window.location?.pathname?.toLowerCase();
  const pathSegments = pathname.split("/");
  const matchedRoute = pathSegments.find((segment) =>
    routeList.includes(segment.toLowerCase())
  );
  const pushPage = () => {
    if (window.location?.pathname === "/" || !!matchedRoute) {
      userTrackingObj.screenViewed(
        screenViewUrlMap(window.location.pathname),
        screenViewUrlMap(window.location.pathname)
      );
    } else {
      userTrackingObj.InvalidPageOpened(
        "Invalid Page Opened",
        screenViewUrlMap(window.location.pathname)
      );
    }
  };
  onUrlChange(pushPage);
 