import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Dropdown, Button, Collapse } from "antd";
import ProfileIcon from "../../static/assets/img/profilePageIcon.svg";
import Profile from "../../static/assets/img/Profile.png";
import UserIcon from "../../static/assets/img/user-icon.svg";
import SuhailBadges from "../../static/assets/img/suhail-badges.svg";
import upArrow from "../../static/assets/img/upArrowIcon.svg";
import FaveIcon from "../../static/assets/img/favIcon.svg";
import LogOutIcon from "../../static/assets/img/logOutIcon.svg";
import downArrow from "../../static/assets/img/downArrowIcon.svg";
import { userTrackingObj } from "userTracking";
import { screenViewUrlMap } from "userTracking/init";
import { useUserContext } from "appContext";
import "./style.scss";

const { Panel } = Collapse;

function UserDropDown({ Region, handleLogout, width }) {
  const [key, setKey] = useState([]);
  const { userData, userBadge } = useUserContext();
  const handleOnCLickButton = (eventName) => {
    userTrackingObj?.ButtonClicked(
      eventName,
      screenViewUrlMap(window.location.pathname),
      screenViewUrlMap(window.location.pathname)
    );
  };

  const menu = (
    <Menu>
      {!!userBadge?.brokerDetails?.title && (
        <div className="user-badge-wrapper">
          <span className="user-badge-name">{userData?.name}</span>
          <span>
            <img
              src={userBadge?.brokerDetails?.badgeIcon}
              alt="user-badge"
              width={24}
            />
            <span style={{ marginRight: 10 }}>
              {userBadge?.brokerDetails?.title} ·{" "}
              {userBadge?.brokerDetails?.type}
            </span>
          </span>
          <span>
            تاريخ الإنضمام:{" "}
            <span className="join-date">
              {userBadge?.brokerDetails?.joinedDate}
            </span>
          </span>
        </div>
      )}
      {localStorage.getItem("user_has_broker") === "true" && (
        <Menu.Item>
          <NavLink
            className="menu-link-item"
            onClick={() => {
              handleOnCLickButton("الصفحة العامة");
            }}
            to={`/profile/${localStorage.getItem("broker_code")}`}
          >
            <img src={ProfileIcon} alt="profile-icon" />
            &nbsp; الصفحة العامة
          </NavLink>
        </Menu.Item>
      )}
      <Menu.Item>
        <NavLink
          className="menu-link-item"
          onClick={() => {
            handleOnCLickButton("الملف الشخصي");
          }}
          to={`/user-profile`}
        >
          <img src={UserIcon} alt="user-icon" />
          &nbsp;الملف الشخصي
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          className="menu-link-item"
          onClick={() => {
            handleOnCLickButton("الأوسمة والإنجازات");
          }}
          to={`/suhail-badges`}
        >
          <img src={SuhailBadges} alt="suhail-badges" />
          &nbsp;الأوسمة والإنجازات
        </NavLink>
      </Menu.Item>
      <Menu.Item>
        <NavLink
          className="menu-link-item"
          onClick={() => {
            handleOnCLickButton("المفضلة");
          }}
          to={`/${Region.key}/favorites`}
        >
          <img src={FaveIcon} alt="fav-icon" />
          &nbsp;المفضلة
        </NavLink>
      </Menu.Item>
      <Menu.Item style={{ textAlign: "start" }}>
        <Button
          className="menu-link-item logout"
          onClick={() => {
            handleLogout();
            handleOnCLickButton("تسجيل الخروج");
          }}
        >
          <img src={LogOutIcon} alt="log-out-icon" />
          &nbsp;تسجيل الخروج
        </Button>
      </Menu.Item>
    </Menu>
  );

  const onCollapceChanged = (key) => {
    setKey(key);
  };

  return (
    <>
      {width > 800 ? (
        <Dropdown overlay={menu} placement="bottomCenter" trigger={"click"}>
          <Button
            className="user-menu-button"
            icon={
              <img
                className="user-image"
                src={userData?.image ? userData?.image?.url : Profile}
                alt="user"
              />
            }
          >
            &nbsp;
            {userData?.name}
          </Button>
        </Dropdown>
      ) : (
        <Collapse bordered={false} ghost={true} onChange={onCollapceChanged}>
          <Panel
            showArrow={false}
            header={
              <div className="collapse-header-wrapper">
                <img
                  className="user-image"
                  src={userData?.image ? userData?.image?.url : Profile}
                  alt="user"
                />
                &nbsp;
                {userData?.name}
                &nbsp;&nbsp;&nbsp;
                <img src={key.length > 0 ? upArrow : downArrow} alt="arrow" />
              </div>
            }
            key="1"
          >
            {menu}
          </Panel>
        </Collapse>
      )}
    </>
  );
}

export default UserDropDown;
