import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import CacheBuster from "cacheBuster";
import { RecoilRoot } from "recoil";
import { ReactNotifications } from "react-notifications-component";
import Providers from "./Providers";
import "./index.scss";

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <Providers>
        <ReactNotifications />
        <CacheBuster>{(props) => <App {...props} />}</CacheBuster>
      </Providers>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
