import React, { useState } from "react";
import axios from "axios";
import "./login.scss";
import { useHistory } from "react-router";
import { useRecoilValue } from "recoil";
import { currentRegion } from "../../data/Atoms";
import PhoneNumber from "components/PhoneNumber";
import LoadingIcon from "../../static/assets/img/loading.svg";

function SavePhoneNumber() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const Region = useRecoilValue(currentRegion);
  let history = useHistory();

  const onHandleTelephoneChange = (e) => {
    let telephone = e.target.value;
    if (!Number(telephone) || e.target.value.length > 9) {
      if (e.target.value.length === 0) {
        setPhoneNumber("");
      }
      return;
    }
    setPhoneNumber(telephone);
  };

  const savePhoneNumber = () => {
    setIsSubmitting(true);
    const dataToSend = {
      PhoneNumber: "+966" + phoneNumber, // Matches Regex "^(\+[0-9]{3}0?)([0-9]{9})$"
    };

    const PUBLIC_API_URL = process.env.REACT_APP_BASE_URL;

    axios
      .put(`${PUBLIC_API_URL}/oauth/user/phone`, dataToSend, {
        headers: {
          authorization: "Bearer " + localStorage.getItem("suhail_user_token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status) {
          history.push(`/${Region.key}/metrics`);
        }
      })
      .catch((error) => {
        console.log(error.data);
      })
      .finally(() => setIsSubmitting(false));
  };
  return (
    <>
      <span className="phone-number-title">شكراً على تسجيل الدخول</span>
      <span className="phone-number-note">
        الرجاء ادخال رقم الهاتف لاتمام عملية التسجيل
      </span>
      <PhoneNumber
        phoneNumber={phoneNumber}
        onHandleTelephoneChange={onHandleTelephoneChange}
      />

      <button
        className="btn btn-primary submit-btn"
        style={{ marginTop: "3rem" }}
        onClick={savePhoneNumber}
      >
        {isSubmitting ? (
          <>
            <img src={LoadingIcon} alt="loading" style={{ width: 30 }} />
          </>
        ) : (
          <>حفظ</>
        )}
      </button>
    </>
  );
}

export default SavePhoneNumber;
