import { useMapContext } from "appContext";

export const useRegionsData = () => {
  const { regData } = useMapContext();
  const CITIES1 = {};

  regData.forEach((city) => {
    const cityKey = city.key;

    CITIES1[cityKey] = {
      key: cityKey,
      id: city.id,
      mapStyleUrl: city?.mapStyleUrl,
      zoom: city.mapZoomLevel,
      minZoom: 8, // You can customize this value as needed
      centroid: { x: city.centroid.x, y: city.centroid.y },
      bounds: [
        [
          city.restrictBoundaryBox.southwest.x,
          city.restrictBoundaryBox.southwest.y,
        ],
        [
          city.restrictBoundaryBox.northeast.x,
          city.restrictBoundaryBox.northeast.y,
        ],
      ],
    };

    if (city.provinces && city.provinces.length > 0) {
      CITIES1[cityKey].provinces = {};
      city.provinces.forEach((province) => {
        CITIES1[cityKey].provinces[province.name] = {
          id: province.id,
          centroid: { x: province.centroid.x, y: province.centroid.y },
        };
      });
    }

    CITIES1[cityKey].image = city.image;
  });

  console.log(CITIES1);

  return { CITIES1 };
};
