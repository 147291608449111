import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Logo.png";
import Quant from "../../static/assets/img/quant.svg";
import { userTrackingObj } from "userTracking";
import { screenViewUrlMap } from "userTracking/init";

const Footer = () => {
  const handleOnClickButton = (title) => {
    userTrackingObj?.ButtonClicked(
      title,
      screenViewUrlMap(window.location.pathname),
      screenViewUrlMap(window.location.pathname)
    );
  };
  return (
    <section className="Footer">
      <div className="header bg-light-navy full-width ">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="navbar">
                <nav className="navbar navbar-expand-lg navbar-dark float-left ">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => {
                      handleOnClickButton("navbar-menu");
                    }}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav R-T-L Edit-nav-bar">
                      <li
                        className="nav-item"
                        onClick={() => {
                          handleOnClickButton("سياسة الخصوصية");
                        }}
                      >
                        <Link className="nav-link " to="/privacy-policy">
                          سياسة الخصوصية{" "}
                          <span className="sr-only">(current)</span>
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          handleOnClickButton("شروط الإستخدام");
                        }}
                      >
                        <Link className="nav-link" to="/agreement">
                          {" "}
                          شروط الإستخدام
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => {
                          handleOnClickButton("مساعدة");
                        }}>
                        <a
                          className="nav-link"
                          href="mailto:suhail_app@quant.sa"
                        >
                          مساعدة
                        </a>
                      </li>
                      <li className="nav-item">
                        <span className="nav-link">كافة الحقوق محفوظة</span>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="col">
                <a
                  id="powerd"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.quant.sa/"
                >
                  <img src={Quant} alt="quant" />
                </a>
              </div>
            </div>

            <div className="clear-fix"></div>
            <div className="col">
              <div className="logo R-T-L">
                <img className="float-right" src={Logo} alt="logo Suhail" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
