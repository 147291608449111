import mixpanel from "mixpanel-browser";
import { userTrackingScreenEvents } from "./userTrackingEvents";
import { HMap, isNullOrEmptyString } from "hd-utils";

export const userTrackingObj = {
  events: userTrackingScreenEvents,
  /**
   *
   * @param {{parcelQuery:string,searchTerm:string, searchTypeSelected:string,cityId:string,subdivisionQuery:string}} data
   */
  locationSearch: function (data) {
    data.searchTypeSelected =
      data.searchTypeSelected === "location" ? "Address" : "Subdivision";

    const props = HMap.from({
      tab: data.searchTypeSelected, // data type:String,  property_type: Event Property ,  sample value: Main, Marketplace // naiberhood, subdivision, address
      "search term address tab": data.searchTerm, // data type:String,  property_type: Event Property ,  sample value: Neighbourhood/subdivision/address // when user searches for address on suahil.ai the value should be this
      "parcel number": data.parcelQuery, // data type:String,  property_type: Event Property ,  sample value:
      "subdivision number": data.subdivisionQuery, // data type:String,  property_type: Event Property ,  sample value:
      region: data?.region,
      province: data?.province,
    })
      .filter((v) => !isNullOrEmptyString(v))
      .toObject();

    this.track(this.events.locationSearch, props);
  },
  identifyUser: function (id) {
    mixpanel.identify(id || localStorage.getItem("suhail_user_id"));
  },
  screenViewed: function (target = "", source = "") {
    userTrackingObj.track(userTrackingScreenEvents.screenViewed, {
      "screen name": target,
      "source screen": source,
    });
  },
  InvalidPageOpened: function (target = "", source = "") {
    userTrackingObj.track(userTrackingScreenEvents.InvalidPageOpened, {
      "screen name": target,
      "source screen": source,
      
    });
  },
  track: function (eventName, params) {
    this.registerChannel();
    console.log(eventName, params);
    mixpanel.track(eventName, params);
  },
  trackSignInUp: function (userInfo) {
    this.identifyUser(userInfo?.id);

    if (userInfo?.isFirstLogin) {
      this.track(this.events.signup);
    }

    if (!userInfo?.isFirstLogin) {
      let method = "Phone";
      const signInProvider = String(userInfo?.signInProvider).toLowerCase();
      if (signInProvider.includes("google")) method = "Google";
      if (signInProvider.includes("apple")) method = "Apple";
      this.track(this.events.login, {
        method,
      });
    }

    mixpanel.people.set({
      user_id: userInfo?.id, // data type:String,  property_type: User Profile Property ,  sample value: 40213e1c-f117-4f60-b1e4-802fd19e1f10
      $email: userInfo?.email, // data type:String,  property_type: User Profile Property ,  sample value: kriti@optiblack.com
      $phone: userInfo?.phoneNumber, // data type:Numeric,  property_type: User Profile Property ,  sample value: 93674645365
      $first_name: userInfo?.firstName, // data type:String,  property_type: User Profile Property ,  sample value: Kriti
      $last_name: userInfo?.firstName, // data type:String,  property_type: User Profile Property ,  sample value: Goel
      $name: userInfo?.name, // data type:String,  property_type: User Profile Property ,  sample value: kriti goel
      $avatar: userInfo?.image?.url || "", // data type:String,  property_type: User Profile Property ,  sample value: https://lh3.googleusercontent.com/a/ACg8ocJ--i4X_AEp5TDHlVfz-xMRgGQLPf1gyf2_eTXq2kGq=s96-c
    });

    mixpanel.people.set_once({
      "registration date": userInfo.createdAt, // data type:Date,  property_type: User Profile Property ,  sample value: YYYY-MM-DDTHH:MM:SS
      "registration method": userInfo.signInProvider, // data type:String,  property_type: User Profile Property ,  sample value: Google, Apple, Phone
    });

    if (!userInfo?.isFirstLogin) {
      mixpanel.people.increment({
        "login count": 1,
      });
    }
  },
  registerChannel: function () {
    mixpanel.register({
      channel: "Web",
    });
  },

  trackLogout: function () {
    this.track(userTrackingScreenEvents.logOut, {
      "logout type": "Voluntary",
    });

    mixpanel.people.increment({
      "logout count": 1,
    });
    mixpanel.reset();
  },
  mapViewChanged: function (type) {
    this.track(userTrackingScreenEvents.mapViewChanged, {
      "map type selected": type ? "automatic" : "satellite",
    });
  },
  MetricsDurationChanged: function (
    oldDue,
    newDue,
    region,
    neighbourhood,
    minPrice,
    maxPrice,
    landUse
  ) {
    this?.track(userTrackingScreenEvents?.metricsDurationChanged, {
      "new duration": newDue,
      "old duration": oldDue,
      region: region,
      neighbourhood: neighbourhood,
      "price per sqm lower limit": minPrice,
      "price per sqm upper limit": maxPrice,
      "land use": landUse,
    });
  },
  showTransactionsOnWeb: function (data) {
    this.track(userTrackingScreenEvents?.mapViewChanged, {
      "transaction data visible indicator": data,
    });
  },
  ItemFavourited: function (data, screen, itemDetails) {
    this.track(userTrackingScreenEvents?.itemFavourited, {
      "item type": data?.entityType,
      "item name": String(data?.parcel?.properties?.parcelObjectId),
      screen: screen,
      "item details": JSON?.stringify(itemDetails),
    });
  },
  ItemShared: function (isLinkShared, parcelData, screen) {
    this.track(userTrackingScreenEvents?.ItemShared, {
      "copied link indicator": isLinkShared,
      screen: screen,
      "item type": "parcel",
      "item name": parcelData?.parcelObjectId,
      "land type": parcelData.landCat,
      "item details": JSON?.stringify(parcelData),
    });
  },
  ItemOpenedInGoogleMaps: function (data, screen, region, itemDetails) {
    this.track(userTrackingScreenEvents.itemOpenedInGoogleMaps, {
      "item type": "parcel",
      "selected land type(s)": data?.landCat,
      screen: screen,
      region: region?.key,
      neighbourhood: data?.neighborhoodParcel,
      "item details": JSON?.stringify(itemDetails),
    });
  },
  ItemBuildingSystem: function (data, screen) {
    this.track(userTrackingScreenEvents.itemBuildingSystem, {
      "item type": "parcel",
      "item details": JSON?.stringify(data),
      "selected land type(s)": data?.landCat,
      screen: screen,
    });
  },
  OnDownLoadReport: function (data, screen) {
    this.track(userTrackingScreenEvents.OnDownLoadReport, {
      "item type": "parcel",
      "item name": data?.parcelObjectId,
      "item details": JSON?.stringify(data),
      "land type": data?.landCat,
      screen: screen,
    });
  },
  OnReportItem: function (parcelId, landType, complainReason, note, screen) {
    this?.track(userTrackingScreenEvents.ItemReported, {
      "parcel Id": parcelId,
      "land type": landType,
      "report reason": complainReason,
      "comment by user": note,
      "Item type": "parcel",
      screen: screen,
    });
  },
  TransactionsSearched: function (
    parcelNumber,
    subdivisionNumber,
    transactionNumber,
    regionName,
    screen
  ) {
    this?.track(userTrackingScreenEvents.TransactionsSearched, {
      region: regionName ? regionName : "",
      "transaction number":
        transactionNumber == 0 ? "" : encodeURIComponent(transactionNumber),
      "parcel number": parcelNumber == 0 ? "" : encodeURI(parcelNumber),
      "subdivision number":
        subdivisionNumber == 0 ? "" : encodeURIComponent(subdivisionNumber),
      screen: screen,
    });
  },

  onBookmarkClicked: function (item, screen) {
    this?.track(userTrackingScreenEvents.BookmarkClicked, {
      "block No": item?.blockNo,
      "land Use Detailed": item?.landUseDetailed,
      "land Use Group": item?.landUseGroup,
      "municipality Name": item?.municipalityName,
      "neighborhood Name": item?.neighborhoodName,
      "parcel Id": item?.parcelId,
      "parcel No": item?.parcelNo,
      screen: screen,
    });
  },
  onProfileCallUs: function (brokerData, screen) {
    this?.track(userTrackingScreenEvents.OnProfileCallUs, {
      id: brokerData?.id,
      code: brokerData?.code,
      name: brokerData?.name,
      "phone number": brokerData?.phoneNumber,
      type: brokerData?.type,
      "approval status": brokerData?.approvalStatus,
      status: brokerData?.status,
      "booking Module Enabled": brokerData?.bookingModuleEnabled,
      "created by id": brokerData?.createdById,
      "is profile compleated": brokerData?.isProfileCompleated,
      "profile url": brokerData?.profileUrl,
      screen: screen,
    });
  },
  onProfileEmailUs: function (brokerData, screen) {
    this?.track(userTrackingScreenEvents.OnProfileEmailUs, {
      id: brokerData?.id,
      code: brokerData?.code,
      name: brokerData?.name,
      "phone number": brokerData?.phoneNumber,
      type: brokerData?.type,
      "approval status": brokerData?.approvalStatus,
      status: brokerData?.status,
      "booking Module Enabled": brokerData?.bookingModuleEnabled,
      "created by id": brokerData?.createdById,
      "is profile compleated": brokerData?.isProfileCompleated,
      "profile url": brokerData?.profileUrl,
      screen: screen,
    });
  },
  onProfileFilterOffers: function (
    organizationId,
    region,
    city,
    neighborhoodId,
    sliderValueMin,
    sliderValueMax,
    screen
  ) {
    this?.track(userTrackingScreenEvents?.OnProfileFilterOffers, {
      "organization id": organizationId,
      region: region,
      city: city,
      "neighborhood id": neighborhoodId,
      "slider value min": sliderValueMin,
      "slider value max": sliderValueMax,
      screen: screen,
    });
  },
  OnClickProjectCard: function (name, icon, city, id) {
    this?.track(userTrackingScreenEvents?.OnClickProjectCard, {
      "project id": id,
      city: city,
      icon: icon,
      name: name,
    });
  },
  MapKeyFiltered: function (mode, data, screen) {
    this?.track(userTrackingScreenEvents?.mapKeyFiltered, {
      "shows colours on map indicator": mode,
      "land use updated indicator": data?.landUseUpdatedIndicator,
      "space updated indicator": data?.spaceUpdatedIndicator,
      "price per meter updated indicator": data?.pricePerMeterUpdatedIndicator,
      "transaction value updated indicator":
        data?.transactionValueUpdatedIndicator,
      "land use selected list": data?.landUseSelectedList,
      "space selected list": data?.spaceSelectedList,
      "price per meter selected list": data?.pricePerMeterSelectedList,
      "transaction value selected list": data?.transactionValueSelectedList,
      screen: screen,
    });
  },
  mapColoursToggled: function (oldStatus, newStatus, screen) {
    this?.track(userTrackingScreenEvents?.mapColoursToggled, {
      "old status": oldStatus,
      "new status": newStatus,
      screen: screen,
    });
  },
  ButtonClicked: function (buttonName, screen, referrerScreen) {
    this?.track(userTrackingScreenEvents?.ButtonClicked, {
      "button name": buttonName,
      "screen name": screen,
      "referrer screen": referrerScreen,
    });
  },
};
