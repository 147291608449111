export const userTrackingScreenEvents = {
  login: "Logged In",
  logOut: "Logged Out",
  signup: "Sign Up",
  screenViewed: "Screen Viewed",
  locationSearch: "Location Searched",
  mapViewChanged: "Map View Changed",
  metricsDurationChanged: "Metrics Duration Changed",
  itemFavourited: "Item Favourited",
  ItemShared: "Item Shared",
  selectParcelClicked: "Select Parcel Clicked",
  itemOpenedInGoogleMaps: "Item Opened In Google Maps",
  itemBuildingSystem: "Item Building System",
  OnDownLoadReport: "Item Details Downloaded",
  ItemReported: "Item Reported",
  TransactionsSearched: "Transactions Searched",
  BookmarkClicked: "Bookmark clicked",
  OnProfileCallUs: "Call us from profile",
  OnProfileEmailUs: "Email us from profile",
  OnProfileFilterOffers: "Profile offers filter",
  OnClickProjectCard: "Market project card",
  mapKeyFiltered: "Map Key Filtered",
  mapColoursToggled: "Map Colours Toggled",
  ButtonClicked:"Button Clicked",
  InvalidPageOpened: "Invalid Page Opened",
};