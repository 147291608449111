import { lazy } from "react";
import Auth from "./Account/Auth";

const Map = lazy(() => import("./Map"));
const Home = lazy(() => import("./Home"));
const ProjectDetails = lazy(() => import("./ProjectDetails"));
const NewProjectDetails = lazy(() => import("./Market/ProjectDetails/inedx"));
const MobileMap = lazy(() => import("./MobileMap"));
const Offer = lazy(() => import("./Offer"));
const Parcel = lazy(() => import("./Parcel"));
const Transactions = lazy(() => import("./Transactions"));
const Transaction = lazy(() => import("./Transaction"));
const Favorites = lazy(() => import("./Favorites"));
const Policy = lazy(() => import("./Policy"));
const Agreement = lazy(() => import("./Agreement"));
const Report = lazy(() => import("./Report"));
const Profile = lazy(() => import("./Profile"));
const Booking = lazy(() => import("./Booking"));
const Market = lazy(() => import("./Market"));
const UserProfile = lazy(() => import("./UserProfile"));
const SuhailBadges = lazy(() => import("./SuhailBadges"));

const WatheerLandingPage = lazy(() =>
  import("./LandingPages/WatheerLandingPage")
);
const SamaAlRosanLandingPage = lazy(() =>
  import("./LandingPages/SamaAlRosanLandingPage")
);
const OsusAlMurjan = lazy(() => import("./LandingPages/OsusAlMurjan"));

const NotFound = lazy(() => import("./NotFound"));
const MapComponent = lazy(() => import("./MapComponent"));
const TestMapComponent = lazy(() => import("./TestMapComponent"));
const POCMapPins = lazy(() => import("./POCMapPins"));
export {
  UserProfile,
  SuhailBadges,
  Profile,
  Map,
  Home,
  ProjectDetails,
  MobileMap,
  Offer,
  Parcel,
  Transaction,
  Transactions,
  POCMapPins,
  Favorites,
  Auth,
  Policy,
  Report,
  Agreement,
  Booking,
  Market,
  WatheerLandingPage,
  SamaAlRosanLandingPage,
  OsusAlMurjan,
  NotFound,
  MapComponent,
  TestMapComponent,
  NewProjectDetails,
};
