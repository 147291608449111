import { atom } from "recoil";
import { CITIES } from "../constants";

export const selectedParcelFilter = atom({
  key: "selectedParcelFilter",
  default: ["==", ["id"], 0],
});

export const selectedParcelsData = atom({
  key: "selectedParcelsData",
  default: [],
});

export const heatmapModals = atom({
  key: "heatmapModals",
  default: null,
});
export const offersData = atom({
  key: "offersData",
  default: [],
});

export const selectedOfferData = atom({
  key: "selectedOfferData",
  default: [],
});
export const selectedOfferParcels = atom({
  key: "selectedOfferParcels",
  default: [],
});

export const currentRegion = atom({
  key: "currentRegion",
  default: CITIES["Riyadh"],
});
export const RegionsData = atom({
  key: "RegionsData",
  default: [],
});
