export const onUrlChange = (callback) => {
   // Listen for popstate events (back/forward navigation)
  window.addEventListener("popstate", callback);

  // Monitor pushState and replaceState to detect URL changes
  const originalPushState = window.history.pushState;
  const originalReplaceState = window.history.replaceState;

    // Override pushState to detect URL changes
  window.history.pushState = function () {
    originalPushState.apply(this, arguments);
    callback();
  };

  // Override replaceState to detect URL changes
  window.history.replaceState = function () {
    originalReplaceState.apply(this, arguments);
    callback();
  };
 return callback();
};
