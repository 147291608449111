const initalState = {
  isLoggedIn: false,
  pulse: true,
};
export default function AuthReducer(state = initalState, { type }) {
  switch (type) {
    case "AUTHENTICATE_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        pulse: !state.pulse,
      };
    case "USERLOGGINGOUT":
      return {
        ...state,
        isLoggedIn: false,
        pulse: !state.pulse++,
      };
    default:
      return state;
  }
}
