import React, { useState, useEffect } from "react";

const Timer = ({ setShowTimer, minInSec = 300 }) => {
  const [minutes, setMinutes] = useState(minInSec / 60 - 1);
  const [seconds, setSecondes] = useState(59);
  let myInterval;
  useEffect(() => {
    myInterval = setInterval(() => {
      setSecondes((seconds) => seconds - 1);
    }, 1000);
    return () => clearInterval(myInterval);
  }, []);

  useEffect(() => {
    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(myInterval);
      } else {
        setMinutes((minutes) => minutes - 1);
        setSecondes(59);
      }
    }
    if (minutes === 0 && seconds === 1) {
      setShowTimer(false);
    }
  }, [seconds, minutes]);

  return (
    <>
      {minutes === 0 && seconds === 0 ? (
        <></>
      ) : (
        <>
          0{minutes} <span>:</span>
          &nbsp;
          {seconds < 10 ? `0${seconds}` : seconds}{" "}
        </>
      )}
    </>
  );
};

export default Timer;
