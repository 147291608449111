import React, { useEffect } from "react";

// components
import Login from "../../../components/Login";
import { useMapContext } from "appContext";

function Auth() {
  const { setShowSelectRegion } = useMapContext();

  useEffect(() => {
    setShowSelectRegion(false);
  }, []);
  return (
    <div className="page-wrapper auth-page">
      <Login />
    </div>
  );
}

export default Auth;
