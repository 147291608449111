import React, { useState, useEffect, useRef } from "react";
import OtpInput from "react-otp-input";
import Recaptcha from "react-google-invisible-recaptcha";
import axios from "../../helpers/axios";
import { useHistory } from "react-router";
import { useFirebaseContext, useUserContext } from "appContext";
import { useRecoilValue } from "recoil";
import { currentRegion } from "../../data/Atoms";
// components
import {
  verifyPhoneNumber,
  generateCustomToken,
  sendEventLog,
  saveSession,
} from "../../api/LoginAPIs";
import Timer from "./Timer";
import PhoneNumber from "components/PhoneNumber";
import LoadingIcon from "../../static/assets/img/loading.svg";
import GoogleIcon from "../../static/assets/img/google-icon.png";
import AppleIcon from "../../static/assets/img/apple-icon.png";
import { userTrackingObj } from "userTracking";
import { errorNotification } from "services/notificationService";
import { signInWithCustomToken, signInWithPopup, signOut } from "firebase/auth";
import "./login.scss";

const PHONE_NUMBER = "PHONE_NUMBER";
const OTP_NUMBER = "OTP_NUMBER";
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const Login = ({ setShowModal }) => {
  let history = useHistory();
  const { auth, googleProvider, appleProvider } = useFirebaseContext();
  const { Loggedin, getUserInfo, getUserBadgeInfo, settingsData } =
    useUserContext();
  const [step, setStep] = useState(PHONE_NUMBER);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationRef, setVerificationRef] = useState("");
  const [userCode, setUserCode] = useState();
  const [countryCode] = useState("+966");

  const captchaRef = useRef();
  const resendOtpCaptchaRef = useRef();
  const [confirmationResult, setConfirmationResult] = useState();
  const [resendOtpConfirmationResult, setResendOtpConfirmationResult] =
    useState();
  const [recaptchaResponse, setRecaptchaResponse] = useState();
  const [showTimer, setShowTimer] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [googleSubmitting, setGoogleSubmitting] = useState(false);
  const [appleSubmitting, setAppleSubmitting] = useState(false);
  const Region = useRecoilValue(currentRegion);

  const handleLogin = async () => {
    await captchaRef?.current?.execute();
  };

  useEffect(() => {
    if (confirmationResult && recaptchaResponse) {
      setIsSubmitting(true);
      verifyPhoneNumber({
        phoneNumber: countryCode + phoneNumber,
        appType: "WEB_APP",
        verificationType: "SIGN_IN",
        recaptchaResponse: recaptchaResponse,
      })
        .then(({ data: { data } }) => {
          setVerificationRef(data?.ref);
          setUserCode("");
          setStep(OTP_NUMBER);
          setShowTimer(true);
        })
        .catch((error) => {
          errorNotification({ message: error?.response?.data?.message });
          captchaRef.current.reset();
          if (error?.response?.status === 408) {
            sendEventLog({
              type: "Event",
              name: "otp_received_timeout",
              data: JSON.stringify({ phoneNumber: countryCode + phoneNumber }),
            });
          }
        })
        .finally(() => setIsSubmitting(false));
    }
  }, [confirmationResult, recaptchaResponse]);

  const checkUserCode = () => {
    setIsSubmitting(true);
    generateCustomToken({
      verificationRef: verificationRef,
      verificationCode: userCode,
    })
      .then(({ data: { data } }) => {
        signInWithCustomToken(auth, data?.customToken)
          .then((userCredential) => {
            // Signed in
            let user = userCredential.user;
            checkUser(user, true);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch(function (error) {
        setIsSubmitting(false);
        errorNotification({ message: error?.response?.data?.message });
      });
  };

  const onHandleTelephoneChange = (e) => {
    let telephone = e.target.value;
    if (!Number(telephone) || e.target.value.length > 9) {
      if (e.target.value.length === 0) {
        setPhoneNumber("");
      }
      return;
    }
    setPhoneNumber(telephone);
  };

  const signInWithGoogle = () => {
    setGoogleSubmitting(true);
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        checkUser(result.user, true);
      })
      .catch((error) => {
        console.log(error);
        setGoogleSubmitting(false);
      });
  };

  const signInWithApple = () => {
    setAppleSubmitting(true);
    signInWithPopup(auth, appleProvider)
      .then((result) => {
        checkUser(result.user, true);
      })
      .catch((error) => {
        console.log(error);
        setAppleSubmitting(false);
      });
  };

  const checkUser = (user, login = false) => {
    localStorage.setItem("suhail_user_id", user.uid);
    localStorage.setItem("suhail_user_refreshToken", user.refreshToken);
    localStorage.setItem("suhail_user_phoneNumber", user.phoneNumber);
    user.getIdToken().then(async function (accessToken) {
      localStorage.setItem("suhail_user_token", accessToken);
      try {
        // get session Id and store it
        const { data: sessionData } = await saveSession();
        localStorage.setItem("sessionId", sessionData?.data);
      } catch (error) {
        console.log(error);
      }
      signInUser(login);
    });
  };

  const signInUser = (login) => {
    axios
      .post(`/oauth/signin`, null, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        getUserInfo();
        getUserBadgeInfo();

        const data = res.data.data;
        userTrackingObj.trackSignInUp(data?.userInfo);

        localStorage.setItem("tokenExpiration", data?.tokenExpiration);
        if (
          !!data.userInfo.memberOfOrg.length &&
          data.userInfo.memberOfOrg[0].organization.isProfileCompleated &&
          data.userInfo.memberOfOrg[0].organization
            .publicBrokerProfileModuleEnabled
        ) {
          localStorage.setItem("user_has_broker", "true");
          localStorage.setItem(
            "broker_code",
            data.userInfo.memberOfOrg[0].organization.code
          );
        } else {
          localStorage.setItem("user_has_broker", "false");
        }
        if (!data.hasPhoneNumber) {
          setShowModal(true);
        } else {
          Loggedin();
          if (localStorage.getItem("parcelUrl")) {
            localStorage.removeItem("parcelUrl");
            history.push(`/${Region.key}/metrics`);
          } else {
            history.push(`/${Region.key}/metrics`);
          }
        }
      })
      .catch((error) => {
        login && errorNotification({ message: error.response.data.message });
        signOut(auth);
        localStorage.removeItem("suhail_user_token");
        setIsSubmitting(false);
        setAppleSubmitting(false);
        setGoogleSubmitting(false);
      })
      .finally(() => {
        setIsSubmitting(false);
        setAppleSubmitting(false);
        setGoogleSubmitting(false);
      });
  };

  const handleResendOtp = async () => {
    await resendOtpCaptchaRef?.current?.execute();
  };

  useEffect(() => {
    if (resendOtpConfirmationResult && recaptchaResponse) {
      verifyPhoneNumber({
        phoneNumber: countryCode + phoneNumber,
        appType: "WEB_APP",
        verificationType: "SIGN_IN",
        recaptchaResponse: recaptchaResponse,
      })
        .then(({ data: { data } }) => {
          setVerificationRef(data?.ref);
          setShowTimer(true);
        })
        .catch((error) => {
          errorNotification({ message: error?.response?.data?.message });
          resendOtpCaptchaRef.current.reset();
          if (error?.response?.status === 408) {
            sendEventLog({
              type: "Event",
              name: "otp_received_timeout",
              data: JSON.stringify({ phoneNumber: countryCode + phoneNumber }),
            });
          }
        });
    }
  }, [resendOtpConfirmationResult, recaptchaResponse]);

  return (
    <>
      {step === PHONE_NUMBER && (
        <>
          <PhoneNumber
            phoneNumber={phoneNumber}
            onHandleTelephoneChange={onHandleTelephoneChange}
          />
          <Recaptcha
            ref={captchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            onResolved={() => {
              setConfirmationResult(true);
              const token = captchaRef?.current?.getResponse();
              setRecaptchaResponse(token);
            }}
          />
        </>
      )}

      {step === OTP_NUMBER && (
        <>
          <div
            className="form-group otp-input-wrapper"
            style={{ margin: "3rem 0 1rem 0" }}
          >
            <OtpInput
              value={userCode}
              onChange={(code) => setUserCode(code)}
              numInputs={6}
              isInputNum={true}
              inputStyle="otp-input"
              shouldAutoFocus={true}
            />
          </div>
          {showTimer ? (
            <div className="resend-text">
              <Timer
                setShowTimer={setShowTimer}
                minInSec={settingsData?.AuthVerificationCodeTimeoutSeconds}
              />
              &nbsp;إعادة الإرسال خلال
            </div>
          ) : (
            <div className="resend-text resend-btn" onClick={handleResendOtp}>
              إعادة الإرسال
            </div>
          )}
          {!showTimer && (
            <Recaptcha
              ref={resendOtpCaptchaRef}
              sitekey={RECAPTCHA_SITE_KEY}
              onResolved={() => {
                setResendOtpConfirmationResult(true);
                const token = resendOtpCaptchaRef?.current?.getResponse();
                setRecaptchaResponse(token);
              }}
            />
          )}
        </>
      )}

      <div className="form-group" style={{ marginTop: "2rem" }}>
        {step === PHONE_NUMBER ? (
          <button
            className="btn btn-primary submit-btn"
            onClick={handleLogin}
            disabled={isSubmitting || phoneNumber?.length < 9}
          >
            {isSubmitting ? (
              <>
                <img src={LoadingIcon} alt="loading" style={{ width: 30 }} />
              </>
            ) : (
              <>ارسال رمز التحقق</>
            )}
          </button>
        ) : (
          <button
            className="btn btn-primary submit-btn"
            onClick={checkUserCode}
            disabled={isSubmitting || userCode?.length !== 6}
          >
            {isSubmitting ? (
              <>
                <img src={LoadingIcon} alt="loading" style={{ width: 30 }} />
              </>
            ) : (
              <>تأكيد التسجيل</>
            )}
          </button>
        )}
      </div>
      {step === OTP_NUMBER && (
        <button
          className="otp-back-button"
          onClick={() => setStep(PHONE_NUMBER)}
          disabled={isSubmitting}
        >
          الرجوع
        </button>
      )}
      {step === PHONE_NUMBER && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "2rem 0",
            }}
          >
            <hr style={{ width: "45%" }} />
            <span>او</span>
            <hr style={{ width: "45%" }} />
          </div>

          <div className="form-group">
            <button
              className="btn btn-primary submit-btn google-btn"
              onClick={signInWithGoogle}
              disabled={googleSubmitting}
            >
              {googleSubmitting ? (
                <>
                  <img src={LoadingIcon} alt="loading" style={{ width: 30 }} />
                </>
              ) : (
                <>
                  تسجيل الدخول عبر جوجل
                  <img
                    src={GoogleIcon}
                    style={{ width: 25, marginLeft: 15 }}
                    alt="google"
                  />
                </>
              )}
            </button>
          </div>
          <div className="form-group">
            <button
              className="btn btn-primary submit-btn apple-btn"
              onClick={signInWithApple}
              disabled={appleSubmitting}
            >
              {appleSubmitting ? (
                <>
                  <img src={LoadingIcon} alt="loading" style={{ width: 30 }} />
                </>
              ) : (
                <>
                  تسجيل الدخول عبر آبل
                  <img
                    src={AppleIcon}
                    style={{
                      width: 25,
                      marginLeft: 15,
                      marginTop: "-6px",
                    }}
                    alt="apple"
                  />
                </>
              )}
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
