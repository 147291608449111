import React, { useState } from "react";
import Logo from "../../black_logo.png";
import Login from "./Login";
import SavePhoneNumber from "./savePhoneNumber";
import "react-notifications-component/dist/theme.css";
import "./login.scss";

function Index() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className="container"
        style={{
          paddingTop: "6rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="auth-section">
          <div className="auth-form">
            <div className="auth-header">
              <img style={{ width: 150 }} src={Logo} alt="logo Suhail" />
            </div>
            {showModal ? (
              <SavePhoneNumber />
            ) : (
              <Login setShowModal={setShowModal} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
