import { Store } from "react-notifications-component";

export function successNotification({ message = "" }) {
  Store.addNotification({
    title: "",
    message: message,
    type: "success",
    insert: "top",
    container: "top-left",
    animationIn: ["animated", "zoomIn"],
    animationOut: ["animated", "zoomOut"],
    dismiss: {
      duration: 4000,
      pauseOnHover: true,
    },
  });
}

export function errorNotification({ message = "" }) {
  Store.addNotification({
    title: "",
    message: message,
    type: "danger",
    insert: "top",
    container: "top-left",
    animationIn: ["animated", "zoomIn"],
    animationOut: ["animated", "zoomOut"],
    dismiss: {
      duration: 4000,
      pauseOnHover: true,
    },
  });
}
