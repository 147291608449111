import React from "react";
import { Button, Modal } from "antd";
import { useRecoilValue } from "recoil";
import { currentRegion } from "../../data/Atoms";
import RegionSelect from "../RegionSelect/RegionSelect";
import { CITIES_MAPPING } from "../../constants";
import Arrow from "../../static/assets/img/downArrow.svg";
import BlueArrow from "../../static/assets/img/blueDownArrow.svg";
import { useMapContext } from "appContext";
import "./Select.scss";

function Select({
  mobile,
  showSelectRegion,
  setShowSelectRegion,
  setExpanded,
}) {
  const Region = useRecoilValue(currentRegion);
  const { setSelectRegionClicked } = useMapContext();
  const handleSelectClick = () => {
    setShowSelectRegion(true);
  };

  return (
    <>
      <Button
        className={`select-button ${mobile && "select-region-mobile"}`}
        icon={<img src={mobile ? BlueArrow : Arrow} alt="down-arrow" />}
        onClick={handleSelectClick}
      >
        {CITIES_MAPPING[Region.key]}
      </Button>
      <Modal
        visible={showSelectRegion}
        centered
        footer={null}
        closable
        onCancel={() => {
          setSelectRegionClicked(false);
          setShowSelectRegion(false);
        }}
      >
        <RegionSelect
          setShowSelectRegion={setShowSelectRegion}
          setExpanded={setExpanded}
        />
      </Modal>
    </>
  );
}

export default Select;
