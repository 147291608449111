import {
  useState,
  useEffect,
  createContext,
  useContext,
  useReducer,
} from "react";
import AuthReducer from "../api/AuthReducer";
import {
  getUserData,
  getUserBadge,
  getUserBadgeStatus,
  getAllUserBadges,
  getSettings,
} from "api/APIs";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [userBadge, setUserBadge] = useState({});
  const [userBadgeStatus, setUserBadgeStatus] = useState({});
  const [allUserBadges, setAllUserBadges] = useState(null);
  const [settingsData, setSettingsData] = useState(null);
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [State, Dispatch] = useReducer(AuthReducer, { isLoggedIn: false });
  const Loggedin = () => {
    return Dispatch({ type: "AUTHENTICATE_SUCCESS" });
  };
  const Loggedout = () => {
    return Dispatch({ type: "USERLOGGINGOUT" });
  };

  const getUserInfo = () => {
    getUserData().then(({ data: { data } }) => setUserData(data));
  };
  const getUserBadgeInfo = () => {
    getUserBadge().then(({ data: { data } }) => setUserBadge(data));
  };
  const getUserBadgeStatusInfo = () => {
    getUserBadgeStatus().then(({ data: { data } }) => setUserBadgeStatus(data));
  };

  const getAppSettings = () => {
    getSettings().then(({ data }) => setSettingsData(data));
  };

  const getUserBadges = () => {
    setUserDataLoading(true);
    getAllUserBadges()
      .then(({ data: { data } }) => setAllUserBadges(data))
      .finally(() => setUserDataLoading(false));
  };

  useEffect(() => {
    if (localStorage.suhail_user_token) {
      getUserInfo();
      getUserBadge();
      getUserBadges();
      getUserBadgeInfo();
    }
    getAppSettings();
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...State,
        Loggedin,
        Loggedout,
        setUserData,
        getUserInfo,
        getUserBadges,
        getUserBadgeInfo,
        getUserBadgeStatusInfo,
        userData,
        userBadge,
        userBadgeStatus,
        settingsData,
        allUserBadges,
        userDataLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  return useContext(UserContext);
};
