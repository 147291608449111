import React, { useEffect, useState, useRef } from "react";
import { Button } from "antd";
import { useFirebaseContext, useMapContext, useUserContext } from "appContext";
import Logo from "../../Logo.png";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentRegion } from "../../data/Atoms";
import Select from "../Select/Select";
import UserDropDown from "./UserDropDown";
import { CITIES } from "../../constants";
import MenuIcon from "../../static/assets/img/menuCollapseIcon.svg";
import { closeSession } from "api/LoginAPIs";
import { userTrackingObj } from "userTracking";
import { screenViewUrlMap } from "userTracking/init";
import { signOut } from "firebase/auth";
import "./style.scss";

const Header = () => {
  const history = useHistory();
  const contentRef = useRef();
  const { auth } = useFirebaseContext();
  const { Loggedout } = useUserContext();
  const { showSelectRegion, selectRegionClicked } = useMapContext();
  const [Region, setCurrentRegion] = useRecoilState(currentRegion);
  const [width, setWidth] = useState(window.innerWidth);
  const [expanded, setExpanded] = useState(false);
  const [showSelectRegionModal, setShowSelectRegionModal] = useState(false);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showSelectRegionModal]);
  const handleClickOutside = (event) => {
    if (
      contentRef &&
      contentRef.current &&
      !contentRef.current.contains(event.target) &&
      !showSelectRegionModal &&
      event?.target?.id !== "menu-btn"
    ) {
      setExpanded(false);
    }
  };
  const handleLogout = () => {
    signOut(auth)
      .then(async function () {
        // get session Id and store it
        await closeSession(localStorage.getItem("sessionId"));
        // Sign-out successful.
        userTrackingObj.trackLogout();
        localStorage.clear();
        Loggedout();
        history.push("/account/auth");
      })
      .catch(function (error) {
        // An error happened.
        // Sign-out successful.
        localStorage.clear();
        Loggedout();
        history.push("/account/auth");
      });
  };
  useEffect(() => {
    if (window.location.href.includes("Madinah") && !selectRegionClicked) {
      setCurrentRegion(CITIES["Madinah"]);
    }
    if (window.location.href.includes("Eastern") && !selectRegionClicked) {
      setCurrentRegion(CITIES["Eastern"]);
    }
    if (window.location.href.includes("Riyadh") && !selectRegionClicked) {
      setCurrentRegion(CITIES["Riyadh"]);
    }
    if (window.location.href.includes("Makkah") && !selectRegionClicked) {
      setCurrentRegion(CITIES["Makkah"]);
    }
    if (window.location.href.includes("Al_Qassim") && !selectRegionClicked) {
      setCurrentRegion(CITIES["Al_Qassim"]);
    }
    if (window.location.href.includes("Asir") && !selectRegionClicked) {
      setCurrentRegion(CITIES["Asir"]);
    }
  }, [window.location.href]);
  const handleOnCLickButton = (title) => {
    userTrackingObj?.ButtonClicked(
      title,
      screenViewUrlMap(window.location.pathname),
      screenViewUrlMap(window.location.pathname)
    );
  };
  return (
    <div
      className="header bg-light-navy full-width "
      id="Header"
      style={{ zIndex: 2 }}
    >
      {width > 800 ? (
        <div className="nav-bar-container">
          <div className="logo-links-wrapper">
            <div className="logo R-T-L">
              <img className="float-right" src={Logo} alt="logo Suhail" />
            </div>
            <ul className="links-header-container">
              <li
                className="nav-link-item"
                onClick={() => {
                  handleOnCLickButton("الرئيسية");
                }}
              >
                <Link to="/" className="nav-link">
                  الرئيسية
                </Link>
              </li>
              <li
                className="nav-link-item"
                onClick={() => {
                  handleOnCLickButton("الخريطة العقارية");
                }}
              >
                <Link className="nav-link" to={`/${Region.key}/metrics`}>
                  الخريطة العقارية
                </Link>
              </li>
              <li
                className="nav-link-item"
                onClick={() => {
                  handleOnCLickButton("الصفقات");
                }}
              >
                <Link className="nav-link" to={`/${Region.key}/transactions`}>
                  الصفقات
                </Link>
              </li>
              <li
                className="nav-link-item"
                onClick={() => {
                  handleOnCLickButton("السوق");
                }}
              >
                <Link className="nav-link" to={`/market`}>
                  السوق
                </Link>
              </li>
            </ul>
            {showSelectRegion && (
              <Select
                showSelectRegion={showSelectRegionModal}
                setShowSelectRegion={setShowSelectRegionModal}
                setExpanded={setExpanded}
              />
            )}
          </div>
          <>
            {!localStorage.getItem("suhail_user_token") ? (
              <Button className="sign-in-button">
                <Link className="nav-link" to="/account/auth">
                  تسجيل الدخول
                </Link>
              </Button>
            ) : (
              <span
                onClick={() => {
                  handleOnCLickButton("user avatar");
                }}
              >
                <UserDropDown
                  Region={Region}
                  handleLogout={handleLogout}
                  width={width}
                />
              </span>
            )}
          </>
        </div>
      ) : (
        <div className="nav-bar-container">
          <div className="logo-links-wrapper">
            <Button
              className="menu-collapse-button"
              onClick={() => setExpanded((expanded) => !expanded)}
            >
              <img src={MenuIcon} alt="menu" id="menu-btn" />
            </Button>
            <div className="logo R-T-L">
              <img className="float-right" src={Logo} alt="logo Suhail" />
            </div>
          </div>
          {!localStorage.getItem("suhail_user_token") && !expanded && (
            <Button className="sign-in-button">
              <Link className="nav-link" to="/account/auth">
                تسجيل الدخول
              </Link>
            </Button>
          )}
          {expanded && (
            <div className="collapse-content" ref={contentRef}>
              {localStorage.getItem("suhail_user_token") && (
                <UserDropDown
                  Region={Region}
                  handleLogout={handleLogout}
                  width={width}
                />
              )}
              <ul className="links-header-container">
                <li className="nav-link-item">
                  <Link
                    to="/"
                    className="nav-link"
                    onClick={() => setExpanded(false)}
                  >
                    الرئيسية
                  </Link>
                </li>
                <li className="nav-link-item">
                  <Link
                    className="nav-link"
                    to={`/${Region.key}/metrics`}
                    onClick={() => setExpanded(false)}
                  >
                    الخريطة العقارية
                  </Link>
                </li>
                <li className="nav-link-item">
                  <Link
                    className="nav-link"
                    to={`/${Region.key}/transactions`}
                    onClick={() => setExpanded(false)}
                  >
                    الصفقات
                  </Link>
                </li>
                <li className="nav-link-item">
                  {/* <Link
                    className="nav-link"
                    to={`/wehdaat`}
                    onClick={() => setExpanded(false)}
                  >
                    وحدات
                  </Link> */}
                  <Link
                    className="nav-link"
                    to={`/market`}
                    onClick={() => setExpanded(false)}
                  >
                    السوق
                  </Link>
                </li>
                {showSelectRegion && (
                  <li className="nav-link-item">
                    <Select
                      showSelectRegion={showSelectRegionModal}
                      setShowSelectRegion={setShowSelectRegionModal}
                      mobile={true}
                      setExpanded={setExpanded}
                    />
                  </li>
                )}
              </ul>
              {!localStorage.getItem("suhail_user_token") && (
                <Button
                  className="sign-in-button-collapsed"
                  onClick={() => setExpanded(false)}
                >
                  <Link className="nav-link" to="/account/auth">
                    تسجيل الدخول
                  </Link>
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
