export const MAP_LEGENDS = [
  {
    mode: "plain",
    label: "",
    legends: [],
  },
  {
    mode: "shape_area",
    title: "المساحة",
    label: "المساحة . متر مربع",
    legends: [
      {
        value: "0-300",
        color: "hsl(64, 63%, 53%)",
      },
      {
        value: "300-500",
        color: "hsl(25, 100%, 61%)",
      },
      {
        value: "500-700",
        color: "hsl(348, 100%, 66%)",
      },
      {
        value: "700-900",
        color: "hsl(312, 56%, 51%)",
      },
      {
        value: ">1200",
        color: "hsl(266, 45%, 46%)",
      },
    ],
  },
  {
    mode: "price_of_meter",
    title: "سعر المتر",
    label: "متوسط سعر المتر . ريال سعودي لكل متر مربع",
    legends: [
      {
        value: "0-200",
        color: "hsl(64, 63%, 53%)",
      },
      {
        value: "200-500",
        color: "hsl(25, 100%, 61%)",
      },
      {
        value: "500-800",
        color: "hsl(348, 100%, 66%)",
      },
      {
        value: "800-1000",
        color: "hsl(312, 56%, 51%)",
      },
      {
        value: ">3000",
        color: "hsl(266, 45%, 46%)",
      },
    ],
  },
  {
    mode: "transaction_price",
    title: "قيمة الصفقة",
    label: "قيمة الصفقة . ريال سعودي",
    legends: [
      {
        value: "0 - 70k",
        color: "hsl(42, 80%, 55%)",
      },
      {
        value: "70k - 175k",
        color: "hsl(25, 100%, 61%)",
      },
      {
        value: "175k - 400k",
        color: "hsl(348, 100%, 66%)",
      },
      {
        value: "400k - 850k",
        color: "hsl(312, 56%, 51%)",
      },
      {
        value: ">10000k",
        color: "hsl(266, 45%, 46%)",
      },
    ],
  },
  {
    mode: "zoning_color",
    title: "استخدام الأراضي ",
    label: "",
    legends: [
      {
        value: "مناطق مفتوحة",
        color: "#a0cbe8",
      },
      {
        value: "شقق تجاري",
        color: "#fa6d6d",
      },
      {
        value: "مرافق",
        color: "#bab0ac",
      },
      {
        value: "تجاري",
        color: "#e74545",
      },
      {
        value: "سكني",
        color: "#f1ce63",
      },
    ],
  },
];

export const CITIES = {
  Riyadh: {
    key: "Riyadh",
    id: 10,
    mapStyleUrl: ["https://www.suhail.ai", "https://suhail.ai"].includes(
      window.location.origin
    )
      ? "https://tiles.suhail.ai/riyadh/"
      : "https://tiles.suhail.ai/style/",
    zoom: 10,
    minZoom: 8,
    centroid: { x: 46.738586, y: 24.774265 },
    bounds: [
      [46.09531588747308, 24.218952735581922], // Southwest coordinates
      [47.55229356242185, 25.19649225695325], // Northeast coordinates
    ],
  },

  Makkah: {
    key: "Makkah",
    id: 4,
    mapStyleUrl: "https://tiles.suhail.ai/makkah_region/",
    zoom: 13,
    minZoom: 8,
    centroid: {
      x: 39.1611,
      y: 21.5292,
    },
    bounds: [
      [38.925834, 20.827453], // Southwest coordinates
      [39.606465, 22.357147], // Northeast coordinates
    ],
  },

  Madinah: {
    key: "Madinah",
    id: 13,
    mapStyleUrl: ["https://www.suhail.ai", "https://suhail.ai"].includes(
      window.location.origin
    )
      ? "https://tiles.suhail.ai/al_madenieh/"
      : "https://tiles.suhail.ai/al_madenieh/",
    zoom: 10,
    minZoom: 9.3,
    centroid: { x: 39.661, y: 24.4736 },
    bounds: [
      [39.413876840778585, 24.301237166504706], // Southwest coordinates
      [39.89940196001879, 24.62763220489326], // Northeast coordinates
    ],
  },
  Eastern: {
    key: "Eastern",
    id: 5,
    mapStyleUrl: ["https://www.suhail.ai", "https://suhail.ai"].includes(
      window.location.origin
    )
      ? "https://tiles.suhail.ai/eastern_region/"
      : "https://tiles.suhail.ai/eastern_region/",
    zoom: 10,
    minZoom: 9.3,
    centroid: { x: 50.091611, y: 26.408637 },
    bounds: [
      [49.720202359250635, 25.9738445337658], // Southwest coordinates
      [50.56331695144152, 26.53230238188388], // Northeast coordinates
    ],
  },

  Al_Qassim: {
    key: "Al_Qassim",
    id: 4,
    mapStyleUrl: "https://tiles.suhail.ai/al_qassim/",
    zoom: 10,
    minZoom: 9.3,
    centroid: { x: 43.9818, y: 26.3592, },
    bounds: [
      [26.137516, 43.662477], // Southwest coordinates
      [44.266213, 26.677408], // Northeast coordinates
    ],
    
  },
  Asir: {
    key: "Asir",
    id: 6,
    mapStyleUrl: "https://tiles.suhail.ai/asir_region/",
    zoom: 10,
    minZoom: 9.3,
    centroid: { x: 42.7662, y: 18.3093 },
    bounds: [
      [42.46391, 18.15379402], // Southwest coordinates
      [42.46391, 18.15379402], // Northeast coordinates
    ],
  },
};

export const CITIES_MAPPING = {
  Riyadh: "الرياض",
  Madinah: "المدينة المنورة",
  Eastern: "الشرقية",
  Makkah: "مكة المكرمة",
  Al_Qassim: "القصيم",
  Asir: "عسير",
};

export const CITIES_MAPPING_AR = {
  الرياض: "Riyadh",
  "منطقة الرياض": "Riyadh",
  "المدينة المنورة": "Madinah",
  "منطقة المدينة المنورة": "Madinah",
  الشرقية: "Eastern",
  "المنطقة الشرقية": "Eastern",
};

export const PROD_ORIGINS = ["https://www.suhail.ai", "https://suhail.ai"];
