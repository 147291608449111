import React, { createContext, useContext } from "react";
import {
  app as firebase,
  auth,
  googleProvider,
  appleProvider,
  remoteConfig,
} from "../firebase";

const FirebaseContext = createContext();

export const FirebaseContextProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        auth,
        googleProvider,
        appleProvider,
        remoteConfig,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => {
  return useContext(FirebaseContext);
};
